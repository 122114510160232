@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter-ui.css');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700');

body { font-family: 'Inter UI', sans-serif; }
@supports (font-variation-settings: normal) {
  body { font-family: 'Inter UI var alt', sans-serif; }
}

@layer base {
  a:hover {
    @apply text-brand;
  }

  p {
    @apply my-4;
  }
}

/* #modal,
#modal * {
  background-color: rgba(255, 0, 0, 0.1);
} */